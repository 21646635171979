
/**
 * @name: 销售管理-订单管理
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 销售管理-订单管理
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import Big from 'big.js'
import {
  saleOrderPageApi,
  saleOrderDetailApi,
  saleOrderExportApi
} from '@/apis/sale/order'
import {
  ISaleOrder,
  ISaleOrderParams
} from '@/apis/sale/order/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from '@/utils/common'

@Component({})
export default class saleOrder extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISaleOrder[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleOrderParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    labelWidth: '120px',
    menu: false,
    viewTitle: '订单详情',
    column: [
      {
        "label": "订单编号",
        "prop": "id",
        "align": "left",
        "width": 180,
        "slot": true
      },
      {
        label: '订单编号',
        prop: 'orderSn',
        search: true,
        hide: true,
        viewHide: true
      },
      {
        "label": "设备编号",
        "prop": "deviceSn",
        "align": "center",
        "width": 150,
        "search": true
      },
      {
        "label": "所属代理商",
        "prop": "agentName",
        "align": "center",
        "width": 150,
        "search": true,
        "overHidden": true
      },
      {
        label: '用户',
        prop: 'userKeywords',
        placeholder: '昵称/手机号',
        search: true,
        hide: true,
        viewHide: true
      },
      {
        "label": "昵称",
        "prop": "nickName",
        "align": "center",
        "width": 150,
        "overHidden": true
      },
      {
        "label": "手机号",
        "prop": "userPhone",
        "align": "center",
        "width": 150
      },
      {
        "label": "消费时长(分钟)",
        "prop": "useDuration",
        "align": "center",
        "width": 100,
        "slot": true,
        viewSlot: true
      },
      {
        "label": "预付金额(元)",
        "prop": "orderPrice",
        "align": "center",
        "width": 100
      },
      {
        "label": "实付金额(元)",
        "prop": "payPrice",
        "align": "center",
        "width": 100
      },
      {
        "label": "退款金额(元)",
        "prop": "refundPrice",
        "align": "center",
        "width": 100
      },
      {
        "label": "订单状态",
        "prop": "status",
        "align": "center",
        "type": "select",
        "search": true,
        dicData: [
          {
            label: '进行中',
            value: 1
          },
          {
            label: '已完成',
            value: 2
          },
        ]
      },
      {
        "label": "设备投放地址",
        "prop": "address",
        "align": "center",
        width: 150,
        "overHidden": true,
        "slot": true,
        viewSlot: true
      },
      {
        "label": "付款时间",
        "prop": "payTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true
      },
      {
        "label": "完成时间",
        "prop": "finishTime",
        "align": "center",
        "width": 150,
        "type": "daterange",
        "search": true
      }
    ]
  }

  useTotalDurationTotal = 0

  orderPriceTotal = 0

  payPriceTotal = 0

  refundPriceTotal = 0

  getUseDuration (seconds: number) {
    if (!seconds) {
      return 0
    }
    if (seconds >= (5 * 60)) {
      // 向上
      return Math.ceil(new Big(seconds).div(60).toNumber())
    } else {
      // 向下
      return Math.floor(new Big(seconds).div(60).toNumber())
    }
  }

  getList () {
    this.tableLoading = true

    const query: ISaleOrderParams = deepCopy(this.queryParam)

    if (query.payTime && query.payTime.length == 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime

    if (query.finishTime && query.finishTime.length == 2) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ''
      query.finishTimeEnd = ''
    }
    delete query.finishTime

    saleOrderPageApi(query).then(e => {
      if (e && e.data) {
        this.useTotalDurationTotal = e.data.useTotalDurationTotal
        this.orderPriceTotal = e.data.orderPriceTotal
        this.payPriceTotal = e.data.payPriceTotal
        this.refundPriceTotal = e.data.refundPriceTotal

        this.tableData = e.data.orderVoCommonPage.list
        this.tableTotal = e.data.orderVoCommonPage.total

        this.tableLoading = false
      }
    })
  }

  openView (row: any, index: number) {
    saleOrderDetailApi(row.id).then(e => {
      if (e && e.data) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e.data, index)
      }
    })
  }

  exportExcel () {

    const query: ISaleOrderParams = deepCopy(this.queryParam)

    if (query.payTime && query.payTime.length == 2) {
      query.payTimeStart = query.payTime[0]
      query.payTimeEnd = query.payTime[1]
    } else {
      query.payTimeStart = ''
      query.payTimeEnd = ''
    }
    delete query.payTime

    if (query.finishTime && query.finishTime.length == 2) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ''
      query.finishTimeEnd = ''
    }
    delete query.finishTime

    saleOrderExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '订单.xlsx')
        this.$message.success('操作成功!')
      }
    })
  }

  created () {
    this.getList()
  }
}
