/**
 * @name: 销售管理-订单管理接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 销售管理-订单管理接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { ISaleOrder, ISaleOrderParams, ISaleOrderRes } from './types';

export const saleOrderPageApi = (params: ISaleOrderParams) => api.get<ISaleOrderRes>('/admin/order/queryByPage', params)

export const saleOrderDetailApi = (id: string) => api.get<ISaleOrder>('/admin/order/detail/' + id)

export const saleOrderExportApi = (params: ISaleOrderParams) => api.get('/admin/order/orderExport', params, 'blob')
